<template>
	<div
		class="full-height flex-column justify-center text-center"
	>
		사용할 수 없는 계정 정보입니다

		관리자에게 문의해주세요
	</div>
</template>

<script>
	export default {
		name: 'AuthNotice'
		,data: function(){

		}
	}
</script>